<template>
    <div class="table-container">
        <b-table-simple bordered no-border-collapse>
            <b-thead>
                <b-tr>
                    <b-th class="toggle-show">
                        <b-button @click="openAll()">
                            <i class="icon icon-chevron-circle icon-rotate-180" v-if="tableOpen"></i>
                            <i class="icon icon-chevron-circle" v-if="!tableOpen"></i>
                        </b-button>
                    </b-th>
                    <b-th colspan="2">
                        Наименование
                    </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr class="table-info">
                    <b-td class="toggle-show">
                        <b-button>
                            <i class="icon icon-chevron-circle icon-rotate-180" v-if="tableOpen"></i>
                            <i class="icon icon-chevron-circle" v-if="!tableOpen"></i>
                        </b-button>
                    </b-td>
                    <b-td colspan="2">
                        <b>Название программы – автоматически тянется из взаимоувязки</b>
                    </b-td>
                </b-tr>
                <b-tr style="background-color: rgb(236, 247, 255);">
                    <b-td></b-td>
                    <b-td class="toggle-show">
                        <b-button>
                            <i class="icon icon-chevron-circle icon-rotate-180" v-if="tableOpen"></i>
                            <i class="icon icon-chevron-circle" v-if="!tableOpen"></i>
                        </b-button>
                    </b-td>
                    <b-td>
                        <b>Наименование цели</b>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td><b>1. Целевой индикатор</b></b-td>
                </b-tr>
                <b-tr style="background-color: #F7F9FC;">
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td>
                        <b-row class="enf-row">
                            <b-col cols="12" md="6">
                                <b-form-group class="enf-form-group" label-cols="3">
                                    <template #label>
                                        на казахском <span class="text-danger">*</span>
                                    </template>
                                    <div class="text-area-block"><span class="enf-placeholder" v-if="!fakeText">Введите описание на казахском</span>{{fakeText}}
                                    </div>
                                    <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group class="enf-form-group" label-cols="3">
                                    <template #label>
                                        на русском <span class="text-danger">*</span>
                                    </template>
                                    <div class="text-area-block"><span class="enf-placeholder">Введите описание на русском</span>
                                    </div>
                                    <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td><b>2. Целевой индикатор</b></b-td>
                </b-tr>
                <b-tr style="background-color: #F7F9FC;">
                    <b-td></b-td>
                    <b-td></b-td>
                    <b-td>
                        <b-row class="enf-row">
                            <b-col cols="12" md="6">
                                <b-form-group class="enf-form-group" label-cols="3">
                                    <template #label>
                                        на казахском <span class="text-danger">*</span>
                                    </template>
                                    <div class="text-area-block"><span class="enf-placeholder" v-if="!fakeText">Введите описание на казахском</span>{{fakeText}}
                                    </div>
                                    <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                                </b-form-group>
                            </b-col>
                            <b-col cols="12" md="6">
                                <b-form-group class="enf-form-group" label-cols="3">
                                    <template #label>
                                        на русском <span class="text-danger">*</span>
                                    </template>
                                    <div class="text-area-block"><span class="enf-placeholder">Введите описание на русском</span>
                                    </div>
                                    <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                                </b-form-group>
                            </b-col>
                        </b-row>
                    </b-td>
                </b-tr>
                <b-tr class="add-indicator">
                    <b-td></b-td>
                    <b-td><i class="icon icon-plus-circle"></i></b-td>
                    <b-td><span><b>Добавить целевой индикатор</b></span></b-td>
                </b-tr>
                <b-tr class="add-indicator">
                    <b-td colspan="3"><span><i
                        class="icon icon-plus-circle"></i><b>Добавить целевой индикатор</b></span></b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>

<script>
    export default {
        name: 'ExplanatoryNoteAddTable',
        props: {},
        data() {
            return {}
        }
    }
</script>