<template>
    <div class="filter-breadcrumb">
        <span class="item-breadcrumb" @click="openFilterByRef('test1')">
            2022-2024
        </span>
        <span class="item-breadcrumb" @click="openFilterByRef('test1')">
            2023
        </span>
        <span class="item-breadcrumb" @click="openFilterByRef('test1')">
            110-АБП
        </span>
        <span class="item-breadcrumb">
            111242-ГУ
        </span>
    </div>
</template>
<script>
export default {
    name: 'CBreadCrumbs',
    props: {},
    methods: {
        openFilterByRef(refName) {
            this.$emit('openFilterByRef', refName);
        }
    }
};

</script>
<style scoped>

</style>
