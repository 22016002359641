<template>
    <div>
        <div class="enf-top-info">
            <div class="filter-breadcrumb no-arrow">
                <span class="item-breadcrumb">
                    1 - Услуги по обеспечению маслихата
                </span>
            </div>
            <div>
                <div class="duplicate-radio">
                    <span class="enf-label">Дублировать на следующие года:</span>
                    <b-form-radio-group id="radio-slots">
                        <b-form-radio>Да</b-form-radio>
                        <b-form-radio>Нет</b-form-radio>
                    </b-form-radio-group>
                </div>
            </div>
        </div>
        <div class="explanatory-note-form form-container p-0">
            <div v-for="t in 4" :key="t">
                <div class="enf-title">Описание достигнутых показателей / результатов за отчетный финансовый год:</div>
                <b-row class="enf-row">
                    <b-col cols="12" md="6">
                        <b-form-group class="enf-form-group" label-cols="3">
                            <template #label>
                                на казахском <span class="text-danger">*</span>
                            </template>
                            <div class="text-area-block"><span class="enf-placeholder" v-if="!fakeText">Введите описание на казахском</span>{{fakeText}}
                            </div>
                            <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group class="enf-form-group" label-cols="3">
                            <template #label>
                                на русском <span class="text-danger">*</span>
                            </template>
                            <div class="text-area-block"><span
                                class="enf-placeholder">Введите описание на русском</span></div>
                            <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>

            <ExplanatoryNoteAddTable/>

            <div v-for="t in 4" :key="t">
                <div class="enf-title">Описание достигнутых показателей / результатов за отчетный финансовый год: <i
                    class="icon icon-pencil-edit"></i></div>
                <b-row class="enf-row">
                    <b-col cols="12" md="6">
                        <b-form-group class="enf-form-group" label-cols="3">
                            <template #label>
                                на казахском <span class="text-danger">*</span>
                            </template>
                            <div class="text-area-block"><span class="enf-placeholder" v-if="!fakeText">Введите описание на казахском</span>{{fakeText}}
                            </div>
                            <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                        </b-form-group>
                    </b-col>
                    <b-col cols="12" md="6">
                        <b-form-group class="enf-form-group" label-cols="3">
                            <template #label>
                                на русском <span class="text-danger">*</span>
                            </template>
                            <div class="text-area-block"><span
                                class="enf-placeholder">Введите описание на русском</span></div>
                            <i class="icon icon-pencil-edit" @click="modalVisible = !modalVisible"></i>
                        </b-form-group>
                    </b-col>
                </b-row>
            </div>
            <div class="add-program"><i class="icon icon-plus-circle"></i><b>Добавить целевой индикатор</b></div>
        </div>
        <b-modal
            v-model="modalVisible"
            modal-class="enf-modal"
            size="lg"
            centered
            title="Описание достигнутых показателей / результатов за отчетный финансовый год:"
            hide-footer
        >
            <b-form-group>
                <template #label>На казахском <span class="text-danger">*</span></template>
                <b-form-textarea placeholder="введите описание на казахском"></b-form-textarea>
            </b-form-group>
            <b-button variant="primary">Сохранить</b-button>
            <b-button variant="light">Отменить</b-button>
        </b-modal>
    </div>
</template>

<script>
    import ExplanatoryNoteAddTable from "./ExplanatoryNoteAddTable";

    export default {
        name: 'ExplanatoryNoteAdd',
        props: {},
        components: {ExplanatoryNoteAddTable},
        data() {
            return {
                fakeText: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. A amet architecto cumque explicabo magnam molestiae possimus quidem sed ullam vero.',
                modalVisible: false
            }
        }
    }
</script>