<template>
    <div class="inner-container explanatory-note">
        <div class="section-title budget"><span>Бюджетное планирование</span></div>
        <div class="section-subtitle budget">
            <i class="icon icon-grid"></i>
            <span>Бюджетные заявки</span>
            <!--            <forms-download-reprt-->
            <!--                :progress="progress"-->
            <!--                :isReportUploading="isReportUploading"-->
            <!--                :form="form"-->
            <!--                :guListLen="guListLen"-->
            <!--                @checkSignatories="checkSignatories"-->
            <!--                @downloadRep="downloadRep"-->
            <!--                @downloadBatchReports="downloadBatchReports"-->
            <!--            ></forms-download-reprt>-->
            <forms-download-reprt/>
        </div>
        <div class="filter-container">
            <div class="left-content">
                <b-dropdown class="filter-dropdown" variant="default" ref="drop">
                    <template #button-content>
                        <span class="lc"><i class="icon icon-filter"></i> Фильтр</span><span class="rc"><i
                        class="icon icon-keyboard"></i></span>
                    </template>
                    <div>
                        <div class="top-content">
                            <span>Параметры фильтра</span>
                            <i class="icon icon-close"></i>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Плановый период">
                                <b-form-select
                                    v-model="fake1[0]"
                                    :options="fake1"
                                />
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Год">
                                <b-form-select
                                    v-model="fake2[0]"
                                    :options="fake2"
                                />
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Функциональная группа">
                                <multiselect
                                    :options="fakeMultiselect"
                                    v-model="fakeMultiselectModel"
                                    placeholder="Выбрать регион"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Адм. программа">
                                <multiselect
                                    :options="fakeMultiselect"
                                    v-model="fakeMultiselectModel"
                                    placeholder="Выбрать регион"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                        <div class="filter-block">
                            <b-form-group label="Гос. учреждение">
                                <multiselect
                                    :options="fakeMultiselect"
                                    v-model="fakeMultiselectModel"
                                    placeholder="Выбрать регион"
                                >
                                </multiselect>
                            </b-form-group>
                        </div>
                    </div>
                </b-dropdown>
            </div>
            <div class="filter-actions">
                <b-button v-if="!openAdd" variant="primary" @click="openAdd = true"><i
                    class="icon icon-plus-circle"></i>Добавить
                </b-button>
                <b-button v-if="openAdd" variant="light" @click="openAdd = false"><i
                    class="icon icon-keyboard icon-rotate-180"></i> Назад к списку
                </b-button>
                <b-button v-if="openAdd" variant="success" @click="openAdd = false">Сохранить</b-button>
            </div>
        </div>
        <c-breadcrumbs></c-breadcrumbs>
        <div v-if="!openAdd" class="table-container">
            <b-table-simple bordered no-border-collapse>
                <b-thead>
                    <b-tr>
                        <b-th class="toggle-show">
                            <b-button @click="openAll()">
                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="tableOpen"></i>
                                <i class="icon icon-chevron-circle" v-if="!tableOpen"></i>
                            </b-button>
                        </b-th>
                        <b-th colspan="3">
                            функц. группа / АБП / ПРОГРАММА / наименование
                        </b-th>
                        <b-th>
                            Статус БЗ
                        </b-th>
                        <b-th>
                            дата последнего <br>
                            редактирование пз
                        </b-th>
                        <b-th></b-th>
                    </b-tr>
                </b-thead>
                <b-tbody>
                    <b-tr class="table-info">
                        <b-td class="toggle-show">
                            <b-button>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="tableOpen"></i>
                                <i class="icon icon-chevron-circle" v-if="!tableOpen"></i>
                            </b-button>
                        </b-td>
                        <b-td colspan="7">
                            1 - Государственные услуги общего характера
                        </b-td>
                    </b-tr>
                    <b-tr style="background-color: rgb(236, 247, 255);">
                        <b-td></b-td>
                        <b-td class="toggle-show">
                            <b-button>
                                <i class="icon icon-chevron-circle icon-rotate-180" v-if="tableOpen"></i>
                                <i class="icon icon-chevron-circle" v-if="!tableOpen"></i>
                            </b-button>
                        </b-td>
                        <b-td colspan="6">
                            1 - Государственные услуги общего характера
                        </b-td>
                    </b-tr>
                    <b-tr v-for="test in 4" :key="test">
                        <b-td></b-td>
                        <b-td></b-td>
                        <b-td>108</b-td>
                        <b-td class="td-link">Услуги по обеспечению деятельности маслихата</b-td>
                        <b-td>
                            <b class="text-success" v-if="test === 1">Согласовано</b>
                            <b class="text-danger" v-else-if="test === 2">Отказано</b>
                            <b v-else>На согласовании</b>
                        </b-td>
                        <b-td>01.01.2001</b-td>
                        <b-td>
                            <b-dropdown id="dropdown-dropleft" dropleft class="more">
                                <template v-slot:button-content>
                                    <i class="icon icon-more"></i>
                                </template>
                                <template>
                                    <b-dropdown-item-btn>
                                        Редактировать
                                    </b-dropdown-item-btn>
                                    <b-dropdown-item-btn>
                                        Добавить
                                    </b-dropdown-item-btn>
                                    <b-dropdown-item-btn>
                                        Скачать
                                    </b-dropdown-item-btn>
                                    <b-dropdown-item-btn class="text-danger">
                                        Удалить
                                    </b-dropdown-item-btn>
                                </template>
                            </b-dropdown>
                        </b-td>
                    </b-tr>
                </b-tbody>
            </b-table-simple>
        </div>


        <ExplanatoryNoteAdd v-if="openAdd"/>


    </div>
</template>


<script>
    import FormsDownloadReprt from "./components/forms-download-reprt.vue";
    import CBreadCrumbs from './components/table-dri-breadcrumbs.vue';
    import ExplanatoryNoteAdd from './ExplanatoryNoteAdd.vue';

    export default {
        name: 'index',
        components: {
            FormsDownloadReprt,
            'c-breadcrumbs': CBreadCrumbs,
            ExplanatoryNoteAdd
        },
        data() {
            return {
                openAdd: false,
                tableOpen: false,
                fake1: ['2020-2022', '2022-2024'],
                fake2: [2020, 2021, 2022, 2023],
                fakeMultiselectModel: null,
                fakeMultiselect: [
                    {
                        name: 'asfasf',
                        title: 'asfsafsa',
                        number: 123
                    },
                    {
                        name: 'asfasf',
                        title: 'asfsafsa',
                        number: 123
                    },
                    {
                        name: 'asfasf',
                        title: 'asfsafsa',
                        number: 123
                    },
                    {
                        name: 'asfasf',
                        title: 'asfsafsa',
                        number: 123
                    }
                ]
            }
        },
        methods: {
            openAll() {
                console.log('open all');
            }
        }
    }

</script>


<style lang="scss">
    .enf-modal {
        .modal-title {
            font-size: 14px;
            font-weight: 700;
            color: #1C2A3E;
            border-bottom: none;
        }

        legend {
            margin-bottom: 10px;
        }

        .form-control {
            min-height: 360px;
            border: 1px solid #BCD2E0;
            border-radius: 5px;
            background-color: #fff;
            font-size: 14px;
            font-weight: 500;
        }
    }

    .explanatory-note {
        .enf-top-info {
            display: flex;
            align-content: center;
            justify-content: space-between;
            border-top: 1px solid #E2E5EB;

            .filter-breadcrumb {
                border-top: none;
            }

            .duplicate-radio {
                margin-top: 6px;
                display: flex;
                align-content: center;

                .enf-label {
                    color: #1C2A3E;
                    font-size: 14px;
                    margin-top: 6px;
                    font-weight: 700;
                    margin-right: 20px;
                }
            }
        }

        .add-program {
            padding: 15px 20px;
            display: inline-flex;
            font-size: 14px;
            align-content: center;
            color: #2196F3 !important;
            cursor: pointer;

            i {
                margin-right: 15px;
                font-size: 20px;
            }

            b {
                text-decoration: underline;
            }
        }

        .td-link:hover {
            color: #2196F3 !important;
            cursor: pointer;
        }

        .more {
            .text-danger {
                button {
                    color: #dc3545 !important;
                }
            }
        }

        .explanatory-note-form {
            .enf-title {
                padding: 10px 20px;
                background-color: #fff;
                font-size: 14px;
                font-weight: 700;
                display: flex;
                align-content: center;
                justify-content: flex-start;

                i {
                    color: #2196F3;
                    font-size: 17px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }

            .enf-row {
                margin: 0;
                padding: 10px;
                border-top: 1px solid #D3DBE3;
                border-bottom: 1px solid #D3DBE3;
            }

            .form-group {
                margin: 0;
            }

            .form-row {
                margin: 0;
                align-items: flex-start;
            }

            .enf-form-group {
                position: relative;

                .icon-pencil-edit {
                    position: absolute;
                    top: 12px;
                    right: 15px;
                    color: #2196F3;
                    cursor: pointer;
                }

                .text-area-block {
                    min-height: 40px;
                    border: 1px solid #BCD2E0;
                    border-radius: 5px;
                    background-color: #fff;
                    padding: 5px 40px 5px 10px;
                    line-height: 1.4;
                    font-size: 14px;
                    font-weight: 500;

                    .enf-placeholder {
                        color: #6087A0;
                    }
                }
            }
        }

        .table-container {
            .add-indicator {
                .icon-plus-circle {
                    font-size: 20px;
                    color: #2196F3;
                }

                span {
                    color: #2196F3;
                    display: inline-flex;
                    align-content: center;
                    cursor: pointer;

                    b {
                        text-decoration: underline;
                    }

                    i {
                        margin-right: 10px;
                    }
                }
            }

            .enf-row {
                margin: 0;
                padding: 0;
                border-top: none;
                border-bottom: none;
            }

        }


    }
</style>